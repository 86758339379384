<template lang="pug">
    #why-hydrate(v-esc-close="performAction")
      .modal-header
        h2 Why do we ask you to drink water?
      .modal-text
        p It is important to be properly hydrated in order to complete the Kit successfully. Please have some water before continuing.
      .modal-footer
        button.button.action-btn(@click="performAction") OK
  </template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    performAction,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
async function performAction() {
  this.$modal.close('WhyHydrate')
}
</script>
